import React from "react";
import Layout from "../components/Layout";
import styled from 'styled-components';
import bgImage from '../assets/images/keyboard-background.jpg';

const ServiceStyles = styled.div`
  
  padding: 80px 40px 20px 40px;
    
  :before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.125;
    background-image: url(${bgImage});
    background-repeat: repeat;
    background-position: 50% 0;
    background-size: cover;
    background-attachment: fixed;
  }

  .heading {
    text-decoration: underline;
  }

  @media screen and (max-width: 1000px) {
    
    padding: 60px 20px 20px 20px;

    ul {
      padding: 0 20px;
    } 
    
  }
`;

export default function Services() {
  return <Layout>
    <ServiceStyles>
      <h3 className='heading'>Translation Services:</h3>
      <p>We offer quality translation services in over 30 languages including:</p>
      <ul>
        <li>Chinese (Mandarin and Cantonese)</li>
        <li>Japanese</li>
        <li>French</li>
        <li>French-Canadian</li>
        <li>Spanish (Latin-American, US, European)</li>
        <li>German</li>
        <li>Italian</li>
        <li>Portuguese (Brazilian, European)</li>
        <li>Korean</li>
        <li>Tagalog (Filipino)</li>
        <li>Vietnamese</li>
        <li>Punjabi</li>
        <li>Hindi</li>
        <li>Russian</li>
        <li>Ukrainian</li>
        <li>Polish</li>
        <li>And more...</li>
      </ul>

      <h3 className='heading'>Multilingual Publishing:</h3>
      <p>Our Multilingual Publishing team has extensive experience in producing documents in single target or multiple language publications. From marketing collateral to large product manuals, our Publishing team (along with our linguists) will make sure your documents will be visually and culturally pleasing to your target audience.</p>

      <p>We are equipped with the latest industry-standard applications in both Mac and Windows platforms including:</p>
      <ul>
        <li>InDesign</li>
        <li>MS Office (Word, Excel, PowerPoint, Publisher)</li>
        <li>FrameMaker</li>
        <li>Acrobat</li>
        <li>Photoshop</li>
        <li>Illustrator</li>
        <li>QuarkXpress</li>
      </ul>
      
      <p>We will analyze your source files for optimal efficiency and integrate them with our Translation Memory (TM) tools. Once the formatting / publishing process is completed, Quality Assurance steps are taken to ensure proper line breaks, integrity of the foreign language fonts and elements specific to your target country / region (such as symbols, punctuation, etc.) are correct. Technical issues such as font embedding, color matching and graphics linkage are checked (preflighted) so the end users will have trouble-free, print ready document files</p>

    </ServiceStyles>
  </Layout>
}
